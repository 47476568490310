import { defineStore } from 'pinia';
import useRemoteResource from '@/hooks/useRemoteResource';
import useCRMApi from '@/hooks/crm/useCRMApi';

const { fetchStatuses } = useCRMApi();

export default defineStore('crmStatus', () => {
  const {
    state,
    fetch,
    fetched,
    getById,
  } = useRemoteResource('crm.status', fetchStatuses);

  return {
    state,
    fetch,
    fetched,
    getById,
  };
});
