import { defineStore } from 'pinia';
import useRemoteResource from '@/hooks/useRemoteResource';
import useCRMApi from '@/hooks/crm/useCRMApi';

const { fetchIndustries } = useCRMApi();

export default defineStore('crmIndustry', () => {
  const {
    state,
    fetch,
    fetched,
    getById,
  } = useRemoteResource('crm.industry', fetchIndustries);

  return {
    state,
    fetch,
    fetched,
    getById,
  };
});
