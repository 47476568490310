<template>
  <vs-form
    v-model="company"
    :errors="feedback"
    i18nNamespace="form.crm.company"
    class="company-form"
  >

    <vs-form-group field="name">
      <b-form-input
        placeholder="Nazwa firmy"
        v-model="company.name"
      />
    </vs-form-group>

    <vs-form-group field="website">
      <b-form-input
        placeholder="https://google.com"
        v-model="company.website"
      />
    </vs-form-group>

    <vs-form-group field="company_status_id">
      <crm-status-picker
        v-model="company.company_status_id"
      />
    </vs-form-group>

    <vs-form-group field="nip">
      <cleave raw
        id="companyNip"
        :value="company.nip"
        @input="(v) => {
          $emit('input:nip')
          const newV = v !== '' ? v : null
          company.nip = newV;
        }"
        class="form-control"
        :options="{ blocks: [3, 3, 2, 2], delimiter: '-' }"
        placeholder="000-000-00-00"
      />
    </vs-form-group>

    <vs-form-group field="regon">
      <b-form-input
        v-model="company.regon"
        placeholder="000000000"
      />
    </vs-form-group>

    <vs-form-group field="krs">
      <b-form-input
        v-model="company.krs"
        placeholder="000000000"
      />
    </vs-form-group>

    <vs-form-group field="branch_ids">
      <core-branch-picker
        multiple
        v-model="company.branch_ids"
      />
    </vs-form-group>

    <vs-form-group field="industry_ids">
      <crm-industry-picker
        multiple
        v-model="company.industry_ids"
      />
    </vs-form-group>

    <vs-form-group field="protected_workplace">
      <b-form-checkbox switch
        size="lg"
        v-model="company.protected_workplace">
      </b-form-checkbox>
    </vs-form-group>

  </vs-form>
</template>

<script>
import {
  BFormInput,
  BFormCheckbox,
} from 'bootstrap-vue';
import { computed } from 'vue';
import Cleave from 'vue-cleave-component';
import CrmStatusPicker from '@/components/module/crm/CrmStatusPicker.vue';
import CoreBranchPicker from '@/components/module/core/CoreBranchPicker.vue';
import CrmIndustryPicker from '@/components/module/crm/CrmIndustryPicker.vue';
import VsForm from '@/components/ui/vs-form/VsForm.vue';
import VsFormGroup from '@/components/ui/vs-form/VsFormGroup.vue';

export default {
  name: 'CompanyForm',
  emits: ['input:nip'],
  props: {
    value: {
      type: Object,
      default: () => ({}),
    },
    feedback: {
      type: Object,
      default: () => ({ value: null }),
    },
  },
  setup(props) {
    const company = computed(() => props.value);

    const errors = computed(() => {
      const ble = {};

      Object.keys(props?.feedback)
        .forEach((key) => {
          ble[key] = props.feedback?.[key]?.[0];
        });

      return ble;
    });

    return {
      company,
      errors,
    };
  },
  components: {
    BFormInput,
    BFormCheckbox,
    Cleave,

    CoreBranchPicker,
    CrmIndustryPicker,
    CrmStatusPicker,
    VsForm,
    VsFormGroup,
  },
};
</script>

<style lang="sass" scoped>
.company-form
  form
    padding: 30px 45px

    @media screen and (max-width: 991px)
      padding: 0px 0px

.company-form::v-deep
  .vs-wysiwyg
    margin-top: 15px
</style>
