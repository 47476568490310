import { defineStore } from 'pinia';
import useRemoteResource from '@/hooks/useRemoteResource';
import useCRMApi from '@/hooks/crm/useCRMApi';

const { fetchServices } = useCRMApi();

export default defineStore('crmService', () => {
  const { state, fetch, fetched } = useRemoteResource('crm.service', fetchServices);

  return {
    state,
    fetch,
    fetched,
  };
});
