var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('vs-form', {
    staticClass: "company-form",
    attrs: {
      "errors": _vm.feedback,
      "i18nNamespace": "form.crm.company"
    },
    model: {
      value: _vm.company,
      callback: function ($$v) {
        _vm.company = $$v;
      },
      expression: "company"
    }
  }, [_c('vs-form-group', {
    attrs: {
      "field": "name"
    }
  }, [_c('b-form-input', {
    attrs: {
      "placeholder": "Nazwa firmy"
    },
    model: {
      value: _vm.company.name,
      callback: function ($$v) {
        _vm.$set(_vm.company, "name", $$v);
      },
      expression: "company.name"
    }
  })], 1), _c('vs-form-group', {
    attrs: {
      "field": "website"
    }
  }, [_c('b-form-input', {
    attrs: {
      "placeholder": "https://google.com"
    },
    model: {
      value: _vm.company.website,
      callback: function ($$v) {
        _vm.$set(_vm.company, "website", $$v);
      },
      expression: "company.website"
    }
  })], 1), _c('vs-form-group', {
    attrs: {
      "field": "company_status_id"
    }
  }, [_c('crm-status-picker', {
    model: {
      value: _vm.company.company_status_id,
      callback: function ($$v) {
        _vm.$set(_vm.company, "company_status_id", $$v);
      },
      expression: "company.company_status_id"
    }
  })], 1), _c('vs-form-group', {
    attrs: {
      "field": "nip"
    }
  }, [_c('cleave', {
    staticClass: "form-control",
    attrs: {
      "raw": "",
      "id": "companyNip",
      "value": _vm.company.nip,
      "options": {
        blocks: [3, 3, 2, 2],
        delimiter: '-'
      },
      "placeholder": "000-000-00-00"
    },
    on: {
      "input": function (v) {
        _vm.$emit('input:nip');
        var newV = v !== '' ? v : null;
        _vm.company.nip = newV;
      }
    }
  })], 1), _c('vs-form-group', {
    attrs: {
      "field": "regon"
    }
  }, [_c('b-form-input', {
    attrs: {
      "placeholder": "000000000"
    },
    model: {
      value: _vm.company.regon,
      callback: function ($$v) {
        _vm.$set(_vm.company, "regon", $$v);
      },
      expression: "company.regon"
    }
  })], 1), _c('vs-form-group', {
    attrs: {
      "field": "krs"
    }
  }, [_c('b-form-input', {
    attrs: {
      "placeholder": "000000000"
    },
    model: {
      value: _vm.company.krs,
      callback: function ($$v) {
        _vm.$set(_vm.company, "krs", $$v);
      },
      expression: "company.krs"
    }
  })], 1), _c('vs-form-group', {
    attrs: {
      "field": "branch_ids"
    }
  }, [_c('core-branch-picker', {
    attrs: {
      "multiple": ""
    },
    model: {
      value: _vm.company.branch_ids,
      callback: function ($$v) {
        _vm.$set(_vm.company, "branch_ids", $$v);
      },
      expression: "company.branch_ids"
    }
  })], 1), _c('vs-form-group', {
    attrs: {
      "field": "industry_ids"
    }
  }, [_c('crm-industry-picker', {
    attrs: {
      "multiple": ""
    },
    model: {
      value: _vm.company.industry_ids,
      callback: function ($$v) {
        _vm.$set(_vm.company, "industry_ids", $$v);
      },
      expression: "company.industry_ids"
    }
  })], 1), _c('vs-form-group', {
    attrs: {
      "field": "protected_workplace"
    }
  }, [_c('b-form-checkbox', {
    attrs: {
      "switch": "",
      "size": "lg"
    },
    model: {
      value: _vm.company.protected_workplace,
      callback: function ($$v) {
        _vm.$set(_vm.company, "protected_workplace", $$v);
      },
      expression: "company.protected_workplace"
    }
  })], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }